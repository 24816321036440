<!-- Template -->
<template>
  <div class="l-auth">
    <aside class="l-auth__aside">
      <OrganismHalfMenu />
    </aside>

    <main class="l-auth__main" ref="scroll">
      <slot></slot>

      <span class="l-auth__main__copyright">
        Copyright {{ year }} - Banco Alfa Todos os direitos reservados
      </span>
    </main>

    <AtomLoading :is-active="isLoading" />
    <OrganismCookieManager/>
  </div>
</template>

<!-- Script -->
<script setup>
// Dependências
import { storeToRefs } from 'pinia'
import { useScreenStore } from '@/stores/screen'
import { user } from './data'
import { userKey } from '~/keys/login'

// Componentes
import OrganismHalfMenu from '~/components/transational/organisms/OrganismHalfMenu/OrganismHalfMenu.vue';
import AtomLoading from '~/components/atoms/AtomLoading/AtomLoading.vue'
import OrganismCookieManager from '~/components/institutional/organisms/OrganismCookieManager/OrganismCookieManager.vue'

// Roteamento
const route = useRoute()

// Dados computados
const title = computed(() => route.meta.title + ' | Alfa Empréstimo Consignado')

// Titulo  da página
useHead({ title })

// Variáveis
const year = new Date().getFullYear()
const userData = ref({ ...user })

// Pinia
const screenStore = useScreenStore()
const { isLoading } = storeToRefs(screenStore)

// Methods
function setUserData(newValue) {
  userData.value = { ...newValue }
}

// Providers
provide(userKey, { userData, setUserData })
</script>

<!-- Style -->
<style lang="scss" scoped>
@import '~/assets/scss/transational/bootstrap.scss';

.l-auth {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  place-content: start stretch;
  width: 100vw;
  height: 100svh;
  background-color: $alfa-neutral-primary-1;
  
  @media screen and (min-width: $min-desktop) {
    grid-template-columns: minmax(min-content, 50%) 1fr;
    grid-template-rows: 1fr;
    background-color: $alfa-brand-primary-1;
  }

  &__aside {
    display: grid;
    place-content: stretch;
    max-height: 100%;
    overflow: hidden;

    @media screen and (min-width: $min-desktop) {
      padding: 0;
    }
  }

  &__main {
    display: grid;
    grid-template-rows: 1fr auto;
    place-content: start stretch;
    overflow: hidden auto;
    padding: 0 24px;
    @include scrollbar();
    @include scrollbox-shadow();

    @media screen and (min-width: $tablet) {
      padding: 0 32px;
    }
    
    @media screen and (min-width: $min-desktop) {
      padding: 0 40px;
    }

    &__copyright {
      display: grid;
      place-content: stretch center;
      grid-template-columns: clamp($min-mobile, 100%, $simulator-max-content);
      padding: 20px 0;
      @include font-body-detail(center);
    }
  }
}
</style>
