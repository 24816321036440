import { defineStore } from 'pinia'

const useScreenStore = defineStore('screen', {
  state: () => ({
    isLoading: null,
    isRequesting: null,
  }),
  getters: {
    getLoading: (state) => {
      return state.isLoading
    },
    getRequesting: (state) => {
      return state.isRequesting
    }
  },
  actions: {
    toggleLoading(payload = false) {
      this.isLoading = payload
    },
    toggleRequesting(payload = false) {
      this.isRequesting = payload
    }
  }
})

export { useScreenStore }